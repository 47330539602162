<template>
  <a-select
    :mode="mode"
    v-model:value="valueState"
    placeholder="Pilih Id"
    allow-clear
    :show-arrow="true"
    not-found-content="Tidak ditemukan"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @select="() => (findText = null)"
    show-search
    v-bind="$attrs"
  >
    <a-select-option key="other" label="other">
      <span>Other</span>
    </a-select-option>
    <a-select-option v-for="d in items" :key="formstep ? d.value : d.id" :label="d.value">
      <!-- eslint-disable vue/no-v-html -->
      <span>{{ d.value }}</span>
      <!--eslint-enable-->
    </a-select-option>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, defineComponent } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'

export default {
  props: {
    value: { type: [Array, Number, Object, String], default: null },
    mode: {
      default: 'multiple',
      type: String,
    },
    formstep: [Boolean],
  },
  emits: ['update:value'],
  setup(props) {
    const items = ref([])
    const findText = ref(null)

    const fetch = () => {
      apiClient.get('/api/filter/loyalty-program/form-step-id').then(response => {
        const sorted = response.data
        items.value = sorted.sort((a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1,
        )
      })
    }

    onMounted(() => {
      fetch()
    })

    const onSearch = value => {
      findText.value = value
    }

    const highlight = value => {
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }
    return { items, valueState: useVModel(props, 'value'), findText, highlight, onSearch }
  },
}
</script>

<style></style>
