<template>
  <a-modal
    :visible="visible"
    :title="model.readOnly ? 'Lihat Program' : form.id ? 'Edit Program' : 'Tambah Program'"
    @ok="handleOk"
    @cancel="handleCancel"
    width="90%"
    :destroy-on-close="true"
  >
    <template #footer>
      <a-button key="back" :hidden="model.readOnly" @click="handleCancel">Cancel</a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="loading"
        :hidden="model.readOnly"
        @click="handleOk"
        >Submit</a-button
      >
    </template>
    <a-form
      ref="formRef"
      class="myform"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 14 }"
      :scroll-to-first-error="true"
    >
      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Kode"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            required
            has-feedback
            name="code"
          >
            <a-input
              placeholder="Kode Program"
              width="100%"
              v-model:value="code"
              :disabled="model.readOnly"
              @keydown.space.prevent
              :maxlength="maxLengthInput"
              required
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Nama"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            required
            has-feedback
            name="name"
          >
            <a-input
              placeholder="Nama Program"
              v-model:value="name"
              :disabled="model.readOnly"
              :maxlength="maxLengthInput"
              required
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Region"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="region"
          >
            <filter-regional
              type="text"
              style="width: 100%"
              v-model:value="region"
              placeholder="Region Program"
              :disabled="model.readOnly"
              :mode="null"
              all
            ></filter-regional>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Gambar Program"
            label-align="left"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
            required
            has-feedback
            name="image"
          >
            <template v-if="!model.readOnly">
              <a-input
                required
                v-if="!image || image === 'null'"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                :key="fileImageKey"
                @change="e => onFileChange(e, 'image')"
              >
                <template #suffix>
                  <a-tooltip title="Recomended: Dimension:710 x 434, File Size:2048Kb, File Type:jpg/png/gif/jpeg">
                    <span
                      role="img"
                      aria-label="question-circle"
                      type="primary"
                      class="anticon anticon-question-circle"
                      ><svg
                        focusable="false"
                        class=""
                        data-icon="question-circle"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                        viewBox="64 64 896 896"
                      >
                        <path
                          d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                        ></path>
                        <path
                          d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"
                        ></path>
                        </svg>
                      </span>
                  </a-tooltip>
                </template>
              </a-input>
              <a-button
                v-else
                @click="
                  () => {
                    image = null
                    url_image = null
                  }
                "
                >Hapus gambar program</a-button
              >
            </template>
            <img
              v-if="image !== 'null'"
              style="display: block;
              max-width:150px;
              width: auto;
              height: auto;"
              :src="typeof image === 'string' ? image : url_image"
            />
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Ikon Program"
            label-align="left"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
            required
            has-feedback
            name="icon"
          >
            <template v-if="!model.readOnly">
              <a-input
                required
                v-if="!icon || icon === 'null'"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                :key="fileIconKey"
                @change="e => onFileChange(e, 'icon')"
              >
                <template #suffix>
                  <a-tooltip title="Recomended: Dimension:313 x 313, File Size:2048Kb, File Type:jpg/png/gif/jpeg">
                    <span
                      role="img"
                      aria-label="question-circle"
                      type="primary"
                      class="anticon anticon-question-circle"
                      ><svg
                        focusable="false"
                        class=""
                        data-icon="question-circle"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                        viewBox="64 64 896 896"
                      >
                        <path
                          d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                        ></path>
                        <path
                          d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"
                        ></path>
                        </svg>
                      </span>
                  </a-tooltip>
                </template>
              </a-input>
              <a-button
                v-else
                @click="
                  () => {
                    icon = null
                    icon_image = null
                  }
                "
                >Hapus ikon program</a-button
              >
            </template>
            <img
              v-if="icon !== 'null'"
              style="display: block;
              max-width:150px;
              width: auto;
              height: auto;"
              :src="typeof icon === 'string' ? icon : icon_image"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Banner Web"
            label-align="left"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
            required
            has-feedback
            name="banner_web"
          >
            <template v-if="!model.readOnly">
              <a-input
                required
                v-if="!banner_web || banner_web === 'null'"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                :key="fileBannerWebKey"
                @change="e => onFileChange(e, 'banner_web')"
              >
                <template #suffix>
                  <a-tooltip title="Recomended: Dimension:1440 x 400, File Size:2048Kb, File Type:jpg/png/gif/jpeg">
                    <span
                      role="img"
                      aria-label="question-circle"
                      type="primary"
                      class="anticon anticon-question-circle"
                      ><svg
                        focusable="false"
                        class=""
                        data-icon="question-circle"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                        viewBox="64 64 896 896"
                      >
                        <path
                          d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                        ></path>
                        <path
                          d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"
                        ></path>
                        </svg>
                      </span>
                  </a-tooltip>
                </template>
              </a-input>
              <a-button
                v-else
                @click="
                  () => {
                    banner_web = null
                    banner_web_image = null
                  }
                "
                >Hapus banner web</a-button
              >
            </template>
            <img
              v-if="banner_web !== 'null'"
              style="display: block;
              max-width:150px;
              width: auto;
              height: auto;"
              :src="typeof banner_web === 'string' ? banner_web : banner_web_image"
            />
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Banner Mobile"
            label-align="left"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
            required
            has-feedback
            name="banner_mobile"
          >
            <template v-if="!model.readOnly">
              <a-input
                required
                v-if="!banner_mobile || banner_mobile === 'null'"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                :key="fileBannerMobileKey"
                @change="e => onFileChange(e, 'banner_mobile')"
              >
                <template #suffix>
                  <a-tooltip title="Recomended: Dimension:400 x 600, File Size:2048Kb, File Type:jpg/png/gif/jpeg">
                    <span
                      role="img"
                      aria-label="question-circle"
                      type="primary"
                      class="anticon anticon-question-circle"
                      ><svg
                        focusable="false"
                        class=""
                        data-icon="question-circle"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                        viewBox="64 64 896 896"
                      >
                        <path
                          d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                        ></path>
                        <path
                          d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"
                        ></path>
                      </svg>
                    </span>
                  </a-tooltip>
                </template>
              </a-input>
              <a-button
                v-else
                @click="
                  () => {
                    banner_mobile = null
                    banner_mobile_image = null
                  }
                "
                >Hapus banner mobile</a-button
              >
            </template>
            <img
              v-if="banner_mobile !== 'null'"
              style="display: block;
              max-width:150px;
              width: auto;
              height: auto;"
              :src="typeof banner_mobile === 'string' ? banner_mobile : banner_mobile_image"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Tanggal Mulai"
            label-align="left"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
            required
            has-feedback
            name="start_date"
          >
            <a-date-picker
              style="width: 100%"
              v-model:value="start_date"
              format="DD MMMM YYYY"
              placeholder="Start Date"
              :disabled-date="disabledStartDate"
              :disabled="model.readOnly"
              required
            />
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Berakhir"
            label-align="left"
            :label-col="{ sm: { span: 8 } }"
            :wrapper-col="{ sm: { span: 24 - 8 } }"
            required
            has-feedback
            name="end_date"
          >
            <a-date-picker
              style="width: 100%"
              v-model:value="end_date"
              :disabled-date="disabledEndDate"
              format="DD MMMM YYYY"
              placeholder="End Date"
              :disabled="model.readOnly"
              required
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Intro Program"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            required
            has-feedback
            name="title_intro"
          >
            <a-input
              placeholder="Intro Judul Program"
              v-model:value="title_intro"
              :disabled="model.readOnly"
              maxlength="180"
              required
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Deskripsi Program"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            required
            has-feedback
            name="description_intro"
          >
            <a-input
              placeholder="Intro Deskripsi Program"
              width="100%"
              v-model:value="description_intro"
              :disabled="model.readOnly"
              maxlength="255"
              required
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Syarat Dan Ketentuan"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            required
            has-feedback
            name="tnc"
          >
            <quill-editor
              class="mb-2"
              v-model:content="tnc"
              content-type="html"
              toolbar="full"
              :read-only="model.readOnly"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Toko"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="toko"
          >
            <filter-toko-by-code
              type="text"
              style="width: 100%"
              v-model:value="toko"
              :disabled="model.readOnly"
              required
            ></filter-toko-by-code>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Status"
            label-align="left"
            :label-col="{ sm: { span: 4 } }"
            :wrapper-col="{ sm: { span: 24 - 4 } }"
            name="active"
          >
            <a-radio-group :disabled="model.readOnly" v-model:value="active" required>
              <a-radio :value="true">Aktif</a-radio>
              <a-radio :value="false">Tidak Aktif</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <template v-for="(item, i) in forms" :key="i">
          <a-col :sm="{ span: 24 }">
            <a-form-item
              :label="'Form step ' + item.step ?? ''"
              label-align="left"
              :label-col="{ sm: { span: 4 } }"
              :wrapper-col="{ sm: { span: 24 - 4 } }"
            >
              <a-input
                v-model:value="item.name"
                prefix="Name:"
                placeholder="......."
                style="width: 300px"
                class="mr-2"
                :disabled="model.readOnly"
                maxlength="50"
              />
              <a-input
                v-model:value="item.description"
                prefix="Description:"
                placeholder="......."
                style="width: 300px"
                class="ml-2 mr-2"
                :disabled="model.readOnly"
                maxlength="255"
              />
              <a-button class="ml-2 mr-2" type="secondary" @click="addField(i)">
                <i class="fa fa-plus">&nbsp;</i>Tambah Field
              </a-button>
              <a-button class="ml-2" type="danger" @click="removeStep(item)">
                <i class="fa fa-plus">&nbsp;</i>Hapus Form Step
              </a-button>
            </a-form-item>
          </a-col>
          <a-col :sm="{ span: 24, offset: 4 }">
            <a-form class="myform-vertical" layout="vertical">
              <template v-for="(val, j) in item.fields" :key="'field-' + j">
                <a-row class="form-row">
                  <a-col :sm="2">
                    <a-form-item label="ID" label-align="left" required>
                      <FilterLoyaltyStepId
                        size="small"
                        v-model:value="val.id"
                        :disabled="model.readOnly"
                        :mode="null"
                        @change="() => (val.defaultValue = val.id)"
                        formstep
                      >
                      </FilterLoyaltyStepId>
                      <a-input
                        v-if="val.id === 'other'"
                        v-model:value="val.other_id"
                        class="mt-3"
                        size="small"
                        :disabled="model.readOnly"
                        maxlength="30"
                        @change="() => (val.defaultValue = val.other_id)"
                        @keydown.space.prevent
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :sm="2">
                    <a-form-item label="Name" label-align="left" required>
                      <a-input
                        v-model:value="val.name"
                        size="small"
                        :disabled="model.readOnly"
                        maxlength="30"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :sm="2">
                    <a-form-item label="Type" label-align="left" required>
                      <filter-loyalty-type
                        size="small"
                        v-model:value="val.type"
                        :disabled="model.readOnly"
                        :mode="null"
                        formstep
                      ></filter-loyalty-type>
                    </a-form-item>
                  </a-col>
                  <a-col :sm="2">
                    <a-form-item label="Default Value" label-align="left" required>
                      <a-input v-model:value="val.defaultValue" size="small" disabled />
                    </a-form-item>
                  </a-col>
                  <a-col :sm="4">
                    <a-form-item label="Attribute" label-align="left" required>
                      <filter-loyalty-attribute
                        size="small"
                        v-model:value="val.attribute"
                        :disabled="model.readOnly"
                      ></filter-loyalty-attribute>
                    </a-form-item>
                  </a-col>
                  <a-col :sm="3">
                    <a-form-item label="Col Length Desktop" label-align="left">
                      <filter-col-length
                        size="small"
                        :mode="null"
                        v-model:value="val.colLengthInDesktop"
                        :disabled="model.readOnly"
                      ></filter-col-length>
                    </a-form-item>
                  </a-col>
                  <a-col :sm="4">
                    <a-form-item label="Col Length Mobile" label-align="left">
                      <filter-col-length
                        size="small"
                        :mode="null"
                        v-model:value="val.colLengthInMobile"
                        :disabled="model.readOnly"
                      ></filter-col-length>
                    </a-form-item>
                  </a-col>
                  <a-col :sm="4">
                    <a-form-item label=" " label-align="left">
                      <a-button
                        @click="removeRow(i, j)"
                        size="small"
                        type="danger"
                        :disabled="model.readOnly"
                        ><i class="fa fa-remove"></i
                      ></a-button>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row v-if="val.type === 'select'" class="form-row">
                  <template v-for="(opt, k) in val.options" :key="'option-' + k">
                    <a-col :sm="{ span: 24 }">
                      <a-form-item :label="'Select options ' + (k + 1)">
                        <a-row class="form-row">
                          <a-col class="mt-2" :sm="6">
                            <a-form-item>
                              <a-input
                                v-model:value="opt.name"
                                size="small"
                                placeholder="Name"
                                :disabled="model.readOnly"
                              />
                            </a-form-item>
                          </a-col>
                          <a-col class="mt-2" :sm="6">
                            <a-form-item>
                              <a-input
                                v-model:value="opt.value"
                                size="small"
                                placeholder="Value"
                                :disabled="model.readOnly"
                              />
                            </a-form-item>
                          </a-col>
                          <a-col class="mt-2" :sm="4">
                            <a-button
                              @click="removeOption(i, j, k)"
                              size="small"
                              type="danger"
                              :disabled="model.readOnly"
                              ><i class="fa fa-remove"></i
                            ></a-button>
                          </a-col>
                        </a-row>
                      </a-form-item>
                    </a-col>
                  </template>
                  <a-col :sm="{ span: 24 }">
                    <template v-if="!model.readOnly">
                      <a-form-item
                        label-align="left"
                        :wrapper-col="{ sm: { span: 24 } }"
                        :colon="false"
                      >
                        <a-button type="dashed" @click="addOption(i, j)">
                          <i class="fa fa-plus">&nbsp;</i>Tambah Option
                        </a-button>
                      </a-form-item>
                    </template>
                  </a-col>
                </a-row>
              </template>
            </a-form>
          </a-col>
        </template>
        <a-col :sm="{ span: 24 }">
          <template v-if="!model.readOnly">
            <a-form-item label-align="left" :wrapper-col="{ sm: { span: 24 } }" :colon="false">
              <a-button type="primary" @click="addForm"
                ><i class="fa fa-plus">&nbsp;</i>Tambah Step Form Pendaftaran</a-button
              >
            </a-form-item>
          </template>
        </a-col>
      </a-row>
    </a-form>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
  </a-modal>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRef,
  toRefs,
  ref,
  computed,
  onBeforeMount,
  onMounted,
} from 'vue'
import apiClient from '@/services/axios'
import { message } from 'ant-design-vue'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterLoyaltyType from '@/components/filter/FilterLoyaltyType'
import FilterLoyaltyStepId from '@/components/filter/FilterLoyaltyStepId'
import FilterLoyaltyAttribute from '@/components/filter/FilterLoyaltyAttribute'
import FilterColLength from '@/components/filter/FilterColLength'
import FilterTokoByCode from '@/components/filter/FilterTokoByCode'
import { serialize } from 'object-to-formdata'
import rules from './rules'
import moment from 'moment'
import { integer } from 'vue-types'

export default defineComponent({
  components: {
    FilterRegional,
    FilterLoyaltyType,
    FilterLoyaltyAttribute,
    FilterTokoByCode,
    FilterColLength,
    FilterLoyaltyStepId,
  },
  props: {
    visible: [Boolean],
    model: {
      type: [Object],
      default: () => ({
        id: null,
        code: null,
        name: null,
        region: null,
        action_link: null,
        image: null,
        banner_web: null,
        banner_mobile: null,
        icon: null,
        title_intro: null,
        description_intro: null,
        tnc: null,
        start_date: null,
        end_date: null,
        default_point: null,
        access_clients_created_by: null,
        access_clients_updated_by: null,
        special_field_id_uom: null,
        list_value_special_field_id_uom: null,
        created_at: null,
        created_by: null,
        updated_at: null,
        updated_by: null,
        is_deleted: false,
        active: false,
        forms: null,
        record: {},
        toko: [],
      }),
    },
  },
  emits: ['handleOk', 'update:visible'],
  setup(props, { emit }) {
    const form = reactive(Object.assign({}, props.model.record))
    const formRef = ref()
    const errorMessage = ref(null)
    const image = toRef(form, 'image')
    const icon = toRef(form, 'icon')
    const banner_web = toRef(form, 'banner_web')
    const banner_mobile = toRef(form, 'banner_mobile')
    const forms = toRef(form, 'forms')
    const toko = toRef(form, 'toko')
    const stepId = ref([])
    const maxLengthInput = 50

    const fileImageKey = ref(0)
    const fileIconKey = ref(0)
    const fileBannerWebKey = ref(0)
    const fileBannerMobileKey = ref(0)

    const dbsStart = computed(() => form.start_date)
    const dbsEnd = computed(() => form.end_date)

    const disabledStartDate = start_date => {
      if (!start_date || !moment(dbsEnd.value)) {
        return false
      }
      return start_date.valueOf() > moment(dbsEnd.value).valueOf()
    }

    const disabledEndDate = end_date => {
      if (!end_date || !moment(dbsStart.value)) {
        return false
      }
      return moment(dbsStart.value).valueOf() >= end_date.valueOf()
    }

    const fotos = reactive({
      url_image: null,
      icon_image: null,
      banner_web_image: null,
      banner_mobile_image: null,
    })

    const formatMoment = value => {
      return value instanceof moment ? value.format('YYYY-MM-DD') : value
    }

    onBeforeMount(() => {
      if (form.forms === undefined || form.forms === null) {
        form.forms = []
      } else {
        form.forms.forEach(val => {
          if (val.fields) {
            if (val.fields !== undefined || val.fields !== null) {
              val.fields.forEach(item => {
                if (item.options === undefined || item.options === null) {
                  item.options = []
                }
                if (item.attribute === undefined || item.attribute === null) {
                  item.attribute = []
                }
              })
            }
          }
        })
        setFirstAndLastStep()
      }
    })

    onMounted(() => {
      fetchDataProgramStore()
      fetchDataStepId()
    })
    // https://api.mdxl.xyz/api/filter/loyalty-program/form-step-id
    const fetchDataStepId = () => {
      apiClient.get('/api/filter/loyalty-program/form-step-id').then(response => {
        let data = response.data
        if (data.length > 0) {
          stepId.value = data
        } else {
          stepId.value = []
        }
      })
    }

    const fetchDataProgramStore = () => {
      apiClient
        .get('/api/loyalty-program-stores/' + form.id + '/vendor-code')
        .then(response => {
          const { items, _meta } = response.data

          if (items.length > 0) {
            let arr = []
            arr.splice(0)

            items?.map(x => arr.push(x.vendor_code))
            form.toko = arr
          } else {
            form.toko = []
          }
        })
        .finally(() => {
          // state.isFetching = false
        })
    }

    const handleOk = async () => {
      try {
        await formRef.value.validate()
      } catch (error) {
        return
      }

      form.code = String(form.code)
      form.active = form.active ? 1 : 0

      const form_data = serialize(
        {
          ..._.omit(form, [
            'start_date',
            'end_date',
            'is_deleted',
            'created_at',
            'updated_at',
            'created_by',
            'updated_by',
            'access_clients_created_by',
            'access_clients_updated_by',
          ]),
          start_date: formatMoment(form.start_date),
          end_date: formatMoment(form.end_date),
          image: image.value,
          icon: icon.value,
          banner_web: banner_web.value,
          banner_mobile: banner_mobile.value,
          toko: toko.value,
        },
        { indices: true },
      )

      // pengecekan step form pendaftaran
      if (forms.value.length > 0) {
        // pengecekan ketika terdapat data null pada
        // object yang ada di forms.value

        let nullValueCount = 0
        forms.value.forEach(element => {
          const hasNull = element.fields.some(function(item) {
            for (let key in item) {
              if (item[key] === null) {
                return true
              } else {
                return false
              }
            }
          })
          if (hasNull === true) { nullValueCount++ }
        })

        if (nullValueCount <= 0) {
          const url = `/api/loyalty-programs${form.id ? '/' + form.id + '?_method=PUT' : ''}`
          apiClient
            .post(url, form_data)
            .then(response => {
              if (response === undefined) {
                errorMessage.value = `Kode error 500, No response from server`
                return
              }
              message.success('Berhasil menyimpan')
              emit('handleOk', form, true)
            })
            .catch(({ response: { data, status } }) => {
              if (status === 422) {
                message.error(data.message)
              } else {
                message.error('Gagal menyimpan data! cek data kembali')
              }
            })
        } else {
          message.error('Gagal menyimpan data! cek data kembali')
        }
      } else {
        const url = `/api/loyalty-programs${form.id ? '/' + form.id + '?_method=PUT' : ''}`
        apiClient
          .post(url, form_data)
          .then(response => {
            if (response === undefined) {
              errorMessage.value = `Kode error 500, No response from server`
              return
            }
            message.success('Berhasil menyimpan')
            emit('handleOk', form, true)
          })
          .catch(({ response: { data, status } }) => {
            if (status === 422) {
              message.error(data.message)
            } else {
              message.error('Gagal menyimpan data! cek data kembali')
            }
          })
      }
    }

    const handleCancel = e => {
      emit('update:visible', false)
    }

    const onFileChange = async (e, type) => {
      var files = e.target.files || e.dataTransfer.files

      const allowed_extensions = new Array('jpg', 'png', 'gif', 'jpeg')
      const isExtOk = fileName => {
        const file_extension = fileName
          .split('.')
          .pop()
          .toLowerCase()
        return allowed_extensions.includes(file_extension)
      }

      if (!files.length) return
      // reset visual of file uploader because of html bug
      const resetVisualUploader = async () => {
        if ('image' === type) {
          image.value = true
        } else if ('icon' === type) {
          icon.value = true
        } else if ('banner_web' === type) {
          banner_web.value = true
        } else if ('banner_mobile' === type) {
          banner_mobile.value = true
        }
        // await nextTick()

        if ('image' === type) {
          image.value = null
          fileImageKey.value++
        } else if ('icon' === type) {
          icon.value = null
          fileIconKey.value++
        } else if ('banner_web' === type) {
          banner_web.value = null
          fileBannerWebKey.value++
        } else if ('banner_mobile' === type) {
          banner_mobile.value = null
          fileBannerMobileKey.value++
        }
      }

      // only 1 file upload allowed, lower than 2mb and some ext of image files
      // but not for document
      if (!isExtOk(files[0].name) && type !== 'document') {
        message.warning(`Hanya Boleh Gambar ${allowed_extensions}`)
        await resetVisualUploader()
        return
      }

      var filesize = (files[0].size / 1024 / 1024).toFixed(4) // MB
      if (filesize > 2.0) {
        message.warning('Ukuran harus di bawah 2MB!', 5.0)
        await resetVisualUploader()
        return
      }

      if ('image' === type) {
        image.value = files[0]
        imageToBase64(image.value, toRefs(fotos).url_image, type)
      } else if ('icon' === type) {
        icon.value = files[0]
        imageToBase64(icon.value, toRefs(fotos).icon_image, type)
      } else if ('banner_web' === type) {
        banner_web.value = files[0]
        imageToBase64(banner_web.value, toRefs(fotos).banner_web_image, type)
      } else if ('banner_mobile' === type) {
        banner_mobile.value = files[0]
        imageToBase64(banner_mobile.value, toRefs(fotos).banner_mobile_image, type)
      }
    }

    const imageToBase64 = (file, to, type) => {

      let heightImg = 0
      let widthImg = 0
      if ('image' === type) {
        heightImg = 434;
        widthImg = 710;
      } else if ('icon' === type) {
        heightImg = 313;
        widthImg = 313;
      } else if ('banner_web' === type) {
        heightImg = 400;
        widthImg = 1440;
      } else if ('banner_mobile' === type) {
        heightImg = 600;
        widthImg = 400;
      }

      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (evt) => {
        const img = new Image();
        img.onload = () => {
          if (img.width === widthImg && img.height === heightImg) {
            to.value = reader.result
          } else {
            message.warning('Rekomendasi dimensi gambar ' + widthImg + " x " + heightImg)
            if ('image' === type) {
              image.value = null
              fileImageKey.value++
            } else if ('icon' === type) {
              icon.value = null
              fileIconKey.value++
            } else if ('banner_web' === type) {
              banner_web.value = null
              fileBannerWebKey.value++
            } else if ('banner_mobile' === type) {
              banner_mobile.value = null
              fileBannerMobileKey.value++
            }
          }
        }
        img.src = evt.target.result;
      }
      reader.onerror = function(error) {
        console.log('Error: ', error)
      }
    }

    const addForm = () => {
      if (forms.value) {
        if (forms.value.filter(v => v.name === null).length > 0) {
          message.warning('Pastikan form name terisi!')
          return
        }
        if (forms.value.filter(v => v.description === null).length > 0) {
          message.warning('Pastikan form description terisi!')
          return
        }
        // if (forms.value.filter(v => v.fields.length === 0).length > 0) {
        //   message.warning('Tambahkan field pada form terlebih dahulu!')
        //   return
        // }
        const m = Object.assign({
          step: forms.value.length + 1,
          name: null,
          description: null,
          isFirstStep: false,
          isLastStep: false,
        })
        m.fields = []
        forms.value.push(m)
        setFirstAndLastStep()
      }
    }

    const addField = index => {
      if (typeof forms.value[index].fields == 'undefined' || forms.value[index].fields == null) {
        forms.value[index].fields = []
      }

      if (forms.value[index].fields.filter(v => v.id === null).length > 0) {
        message.warning('Pastikan field id terisi!')
        return
      }
      if (forms.value[index].fields.filter(v => v.name === null).length > 0) {
        message.warning('Pastikan field name terisi!')
        return
      }
      if (forms.value[index].fields.filter(v => v.type === null).length > 0) {
        message.warning('Pastikan field type terisi!')
        return
      }
      forms.value[index].fields.push({
        id: null,
        name: null,
        type: null,
        defaultValue: null,
        options: [],
        attribute: [],
        colLengthInDesktop: null,
        colLengthInMobile: null,
        other_id: null,
      })
    }

    const addOption = (x, y) => {
      forms.value[x].fields[y].options.push({
        name: null,
        value: null,
      })
    }

    const removeStep = item => {
      const index = forms.value.indexOf(item)
      forms.value.splice(index, 1)
      setFirstAndLastStep()
    }

    const removeRow = (i, j) => {
      forms.value[i].fields.splice(j, 1)
    }

    const removeOption = (i, j, k) => {
      forms.value[i].fields[j].options.splice(k, 1)
    }

    const setFirstAndLastStep = () => {
      forms.value.forEach(val => {
        val.isFirstStep = false
        val.isLastStep = false
      })
      forms.value.at(0).isFirstStep = true
      forms.value.at(-1).isLastStep = true
    }

    return {
      image,
      icon,
      banner_web,
      banner_mobile,
      form,
      toko,
      stepId,
      ...toRefs(form),
      emit,
      rules,
      handleOk,
      handleCancel,
      formRef,
      errorMessage,
      onFileChange,
      ...toRefs(fotos),
      forms,
      addForm,
      addField,
      addOption,
      removeStep,
      removeRow,
      removeOption,
      fetchDataProgramStore,
      fetchDataStepId,
      maxLengthInput,
      disabledStartDate,
      disabledEndDate,
      fileImageKey,
      fileIconKey,
      fileBannerWebKey,
      fileBannerMobileKey,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
