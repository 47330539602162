<template>
    <a-modal
        :visible="visibleConversionPoint"
        title="Konversi Poin"
        @ok="ConversionPoint"
        @cancel="handleCancel"
        width="90%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @keydown="form.onKeydown($event)"
            @submit.prevent="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- rule name -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Nama Rule"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="rule"
                        has-feedback>
                        <AInput
                            style="width: 100%"
                            :disabled="form.edit_status"
                            placeholder="nama rule"
                            v-model:value="form.rule" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- produk -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Produk"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="product_id"
                        has-feedback>
                        <FilterProduct
                            style="width: 100%"
                            :disabled="form.edit_status"
                            v-model:value="form.product_id"
                            show-search/>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="UOM Produk"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="product_uom"
                        has-feedback>
                        <ASelect
                            v-if="form.product_id.length > 1 || form.edit_status"
                            class="mr-2"
                            v-model:value="form.product_uom"
                            show-search
                            allow-clear
                            placeholder="Pilih Produk UOM"
                            :disabled="form.edit_status"
                            style="width:100%;"
                            :options="state.product_uom_lists"
                        />
                        <FilterProductUom
                            v-else
                            style="width:100%;"
                            v-model:value="form.product_uom"
                            v-model:product_id="form.product_id"
                            :disabled="form.edit_status"
                            placeholder="uom produk"
                            :mode="null"
                            all/>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="QTY"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="qty"
                        has-feedback>
                        <AInputNumber
                            placeholder="qty"
                            style="width: 100%"
                            :min="1"
                            :disabled="form.edit_status"
                            v-model:value="form.qty"
                            type="number"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Poin"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="point"
                        has-feedback>
                        <AInputNumber
                            placeholder="poin"
                            style="width: 100%"
                            :min="1"
                            type="number"
                            :disabled="form.edit_status"
                            v-model:value="form.point"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="UOM Program"
                        label-align="left"
                        required
                        name="point_uom_id"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }">
                        <FilterLoyaltyUom
                            type="text"
                            style="width: 100%"
                            v-model:value="form.point_uom_id"
                            v-model:program="form.program_id"
                            :disabled="form.edit_status"
                            placeholder="uom program"
                            :mode="null"
                            all/>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Tanggal Mulai dan Akhir"
                        label-align="left"
                        required
                        name="range_date"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }">
                        <ARangePicker
                            :disabled-date="disabledDate"
                            :placeholder="['Tanggal Mulai', 'Tanggal Akhir']"
                            :disabled="form.edit_status"
                            v-model:value="form.range_date"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row class="form-row">
                <a-col :sm="24">
                    <AFormItem
                        label="Status"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        has-feedback>
                        <ARadioGroup v-model:value="form.active" required>
                            <a-radio :value="1">Aktif</a-radio>
                            <a-radio :value="0">Non Aktif</a-radio>
                        </ARadioGroup>
                    </AFormItem>
                </a-col>
            </a-row>

            <a-row class="form-row" type="flex" justify="end">
                <a-col>
                    <a-form-item>
                        <ASpace>
                            <ATooltip title="Download Excel Mapping">
                                <DownloadExcel
                                    :url="state.endpoint.current"
                                    :params="state.params"
                                    namefile="Konversi-Poin-Master-Program-Loyalty"
                                    @errors="errorMessage"/>
                            </ATooltip>
                            <a-button
                                type="primary"
                                html-type="submit"
                                :loading="form.busy"
                                :disabled="form.busy">{{
                                !form.edit_status ? 'Simpan' : 'Perbarui'
                            }}</a-button>
                        </ASpace>
                    </a-form-item>
                </a-col>
            </a-row>
        </AForm>

        <div v-if="form.errors.any()">
            <ASpace>
                <AAlert
                    v-for="(item, index) in form.errors.all()"
                    type="error"
                    :key="index"
                    :message="item.message"
                    banner
                    closable
                    @close="form.clear()" />
            </ASpace>
        </div>

        <br>

        <ATable
            :data-source="state.data"
            :columns="state.columns"
            size="small"
            :pagination="{
                showSizeChanger: true,
                pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                total: state.meta.total,
                pageSize: state.meta.per_page,
                current: state.meta.page,
            }"
            :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
            @change="handleTableChange"
            :loading="state.isFetching">
            <template #product="{ record }">
                <span v-for="(item, index) in record.product" :key="index">
                    <p>{{ item.product_name }}</p>
                </span>
            </template>
            <template #action="{ record }">
                <span>
                    <a-tooltip title="Edit">
                        <a-button
                            class="button"
                            size="small"
                            :disabled="!record.is_edit"
                            @click="btnEdit(record)">
                            <i class="fe fe-edit" />
                        </a-button>
                    </a-tooltip>
                    <!-- <a-tooltip title="Hapus">
                        <a-button
                            class="button"
                            size="small"
                            @click="btnDelete(record.id)">
                            <i class="fe fe-trash" />
                        </a-button>
                    </a-tooltip> -->
                </span>
            </template>
        </ATable>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import Form from 'vform'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterLoyaltyUom from '@/components/filter/FilterLoyaltyUom'
import FilterProduct from '@/components/filter/FilterProduct'
import FilterProductUom from '@/components/filter/FilterProductUom'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import moment from 'moment'
import { toInteger } from 'lodash'

export default defineComponent({
    components: {
        FilterLoyaltyUom,
        FilterProduct,
        FilterProductUom,
        DownloadExcel,
    },
    props: {
        visibleConversionPoint: [Boolean],
        model: {
            type: [Object],
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['handleOkConversionPoint', 'update:visibleConversionPoint'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            program_id: props.model.record.id,
            rule: '',
            product_id: [],
            product_uom: '',
            qty: '',
            point: '',
            point_uom_id: '',
            active: 1,
            start_date: '',
            end_date: '',
            range_date: null,
            edit_status: false,
        }))

        const disabledDate = (current) => {
            return current && current < moment(props.model.record.start_date, 'YYYY-MM-DD') || current > moment(props.model.record.end_date, 'YYYY-MM-DD').add(1, 'd')
        };

        const formRef = ref();

        const state = reactive({
            columns: [
                {
                    title: 'Nama Rule',
                    dataIndex: 'rule',
                },
                {
                    title: 'Produk',
                    slots: { customRender: 'product' },
                },
                {
                    title: 'UOM Produk',
                    dataIndex: 'product_uom_name',
                },
                {
                    title: 'QTY',
                    dataIndex: 'qty',
                },
                {
                    title: 'Poin',
                    dataIndex: 'point',
                },
                {
                    title: 'UOM Program',
                    dataIndex: 'point_uom_name',
                },
                {
                    title: 'Tanggal Mulai',
                    dataIndex: 'start_date',
                },
                {
                    title: 'Tanggal Akhir',
                    dataIndex: 'end_date',
                },
                {
                    title: 'Status',
                    dataIndex: 'status_active',
                },
                {
                    title: 'Action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: {
                current: '/api/loyalty-point-conversions',
            },
            data: [],
            total: 0,
            isFetching: false,
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            params: {
                program: props.model.record.id,
                page: 1,
                per_page: 10,
            },
            product_uom_lists: [
                {
                    label: "TON",
                    value: "ton",
                },
                {
                    label: "ZAK",
                    value: "zak",
                },
            ],
            rules: {
                rule: [
                    {
                        required: true,
                        message: "Nama rule tidak boleh kosong!",
                    },
                ],
                product_id: [
                    {
                        required: true,
                        message: "Produk tidak boleh kosong!",
                    },
                ],
                product_uom: [
                    {
                        required: true,
                        message: "UOM produk tidak boleh kosong!",
                    },
                ],
                qty: [
                    {
                        required: true,
                        message: "QTY tidak boleh kosong!",
                    },
                ],
                point: [
                    {
                        required: true,
                        message: "Poin tidak boleh kosong!",
                    },
                ],
                point_uom_id: [
                    {
                        required: true,
                        message: "UOM program tidak boleh kosong!",
                    },
                ],
                range_date: [
                    {
                        required: true,
                        message: "Tanggal tidak boleh kosong!",
                    },
                ],
            },
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const handleCancel = e => {
            emit('update:visibleConversionPoint', false)
        }

        const handleOk = async () => {
            if (form.product_id.length > 1) {
                form.product_uom_name = form.product_uom
            } else {
                form.product_uom_id = form.product_uom
            }

            if (form.range_date) {
                form.start_date = moment(form.range_date[0]).format('YYYY-MM-DD')
                form.end_date = moment(form.range_date[1]).format('YYYY-MM-DD')
            }

            // validation form
            await formRef.value.validate()
                .catch(() => {});

            if (!form.edit_status) {
                form.post(state.endpoint.current)
                    .then(response => {
                        if (response === undefined) {
                            errorMessage.value = `Kode error 500, No response from server`
                            return
                        }

                        message.success('Berhasil menyimpan')

                        fetchDataList()
                        form.reset()
                        formRef.value.resetFields()
                    })
            } else {
                form.put(`${state.endpoint.current}/${form.rule}`)
                    .then(response => {
                        if (response === undefined) {
                            errorMessage.value = `Kode error 500, No response from server`
                            return
                        }

                        message.success('Berhasil memperbarui')

                        fetchDataList()
                        form.reset()
                        formRef.value.resetFields()
                    })
            }

        }

        // Convert Point By Product - GET DATA
        const fetchDataList = () => {
            state.isFetching = true
            apiClient
                .get(state.endpoint.current, {
                    params: state.params,
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const btnDelete = id => {
            Modal.confirm({
                title: 'Konfirmasi hapus konversi poin',
                content: 'Apakah anda ingin hapus konversi poin?',
                onOk() {
                    apiClient
                        .delete(`${state.endpoint.current}/${id}`)
                        .then((response) => {
                            fetchDataList()
                            message.success('Berhasil dihapus')
                        })
                        .catch(e => message.error('Gagal menghapus!'))
                },
                onCancel() { },
            })
        }

        const btnEdit = (item) => {
            formRef.value.resetFields()
            form.clear()

            form.edit_status = true
            form.rule = item.rule
            form.product_id = item.product.map(item => item.product_id)
            form.product_uom = item.product_uom_name
            form.qty = item.qty
            form.point = item.point
            form.range_date = [
                moment(item.start_date, 'YYYY-MM-DD'),
                moment(item.end_date, 'YYYY-MM-DD'),
            ]
            form.point_uom_id = item.point_uom_id
            form.active = toInteger(item.status_active_id)
        }

        // handle func vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            formRef,
            disabledDate,
            btnDelete,
            btnEdit,
            handleOk,
            form,
            fetchDataList,
            state,
            handleCancel,
            handleTableChange,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
