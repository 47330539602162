<template>
    <a-select
        mode="multiple"
        v-model:value="valueState"
        style="width: 300px"
        placeholder="Pilih User Pic"
        allow-clear
        show-search
        not-found-content="Tidak ditemukan"
        option-filter-prop="label"
        option-label-prop="label"
        @search="onSearch"
        @dropdownVisibleChange="() => (state.params.q = '')"
        v-bind="$attrs"
        :loading="state.loading"
        :show-arrow="true">
        <a-select-option v-for="d in state.data" :key="d.id" :label="`${d.nopeg} - ${d.fullname}`">
            <span v-html="highlight(`${d.nopeg} - ${d.fullname}`)"></span>
        </a-select-option>
    </a-select>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce, toString } from 'lodash'

export default {
    props: {
        value: {
            default: () => [],
            type: Array,
        },
        role_id: {
            default: () => '',
            type: [String, Number],
        },
        nopeg_status: {
            default: () => '',
            type: [String, Number],
        },
    },
    emits: ['update:value'],
    setup(props, { emit }) {
        const state = reactive({
            endpoint: '/api/filter/user-by-role',
            loading: false,
            data: [],
            params: {
                q: '',
                role_id: props.role_id,
                nopeg_status: props.nopeg_status,
            },
        })

        const fetchLists = () => {
            state.loading = true
            apiClient
                .get(state.endpoint, { params: state.params })
                .then(({ data }) => {
                    state.data = data.sort((a, b) =>
                        toString(a.fullname).toLowerCase() > toString(b.fullname).toLowerCase() ? 1 : -1,
                    )
                })
                .finally(() => {
                    state.loading = false
                })
        }

        const onSearch = (value) => {
            state.params.q = value
        }

        const highlight = value => {
            return toString(value).replace(new RegExp(state.params.q, 'gi'), match => {
                return `<span style="background-color: yellow;">${match}</span>`
            })
        }

        onMounted(() => {
            fetchLists()
        })

        watch(state.params, debounce(() => {
            fetchLists()
        }, 300))

        return {
            state,
            valueState: useVModel(props, 'value'),
            highlight,
            onSearch,
        }
    },
}
</script>
