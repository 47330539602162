const acolumns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Kode Program',
    dataIndex: 'code',
  },
  {
    title: 'Nama Program',
    dataIndex: 'name',
  },
  {
    title: 'Region',
    dataIndex: 'region',
  },
  {
    title: 'Start Date',
    dataIndex: 'start_date',
    slots: { customRender: 'formatDate' },
  },
  {
    title: 'End Date',
    dataIndex: 'end_date',
    slots: { customRender: 'formatDate' },
  },
  {
    title: 'Status',
    dataIndex: 'active',
    slots: { customRender: 'status' },
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]
export default acolumns
