export default {
    code: {
        required: true,
        message: 'Kode program tidak boleh kosong!',
    },
    name: {
        required: true,
        message: 'Nama program tidak boleh kosong!',
    },
    image: {
        required: true,
        message: 'Gambar program tidak boleh kosong!',
    },
    icon: {
        required: true,
        message: 'Icon program tidak boleh kosong!',
    },
    banner_web: {
        required: true,
        message: 'Gambar banner web tidak boleh kosong!',
    },
    banner_mobile: {
        required: true,
        message: 'Gambar banner mobile tidak boleh kosong!',
    },
    title_intro: {
        required: true,
        message: 'Intro judul program tidak boleh kosong!',
    },
    description_intro: {
        required: true,
        message: 'Intro deskripsi program tidak boleh kosong!',
    },
    tnc: {
        required: true,
        message: 'Syarat dan ketentuan tidak boleh kosong!',
    },
    start_date: {
        required: true,
        message: 'Tanggal mulai tidak boleh kosong!',
    },
    end_date: {
        required: true,
        message: 'Tanggal berakhir tidak boleh kosong!',
    },
}