<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-regional class="mr-2" v-model:value="regional" />
        <a-date-picker
          style="width: 300px;"
          class="mr-2 mt-2"
          v-model:value="periode"
          format="DD MMMM YYYY"
          placeholder="Periode"
        />
        <a-select
          :options="[
            { value: 1, label: 'Aktif' },
            { value: 0, label: 'Tidak Aktif' },
          ]"
          :mode="null"
          allow-clear
          class="mr-2 mt-2"
          style="width: 300px;"
          placeholder="Pilih Status"
          v-model:value="status"
        >
        </a-select>
        <a-input-search
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
          class="mr-2 mt-2"
        />
        <a-button class="mt-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end mt-4">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button title="Tambah Program" type="primary" @click="showModal">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>

        <!-- <a-button
          class="ml-2"
          title="download excel"
          type="primary"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button> -->
      </div>
    </div>
    <div class="table-responsive text-nowrap mt-2">
      <md-table
        row-key="id"
        :columns="columns"
        :data-source="data"
        size="small"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #formatDate="{text}">
          <span>{{ text ? moment(text).format('DD-MM-yyyy') : '' }}</span>
        </template>
        <template #status="{ text }">
          <a-tag v-if="text" color="#5cb85c">active</a-tag>
          <a-tag v-else color="#fd6464">inactive</a-tag>
        </template>
        <template #action="{record}">
          <span>
            <a-tooltip title="List Toko">
              <a-button
                class="button"
                size="small"
                @click="
                  () => {
                    router.push({
                      path: `/program-loyalty/${record.id}/list-toko`,
                    })
                  }
                "
              >
                <i class="fe fe-list" />
              </a-button>
            </a-tooltip>
            <a-tooltip title="Assign Toko Program">
              <a-button
                class="button"
                size="small"
                @click="
                  () => {
                    router.push({
                      path: `/program-loyalty/${record.id}/assign-toko`,
                    })
                  }
                "
              >
                <i class="fe fe-check-square" />
              </a-button>
            </a-tooltip>
            <a-tooltip title="Lihat Program">
              <a-button class="button" size="small" @click="lihatProgram(record)">
                <i class="fe fe-eye" />
              </a-button>
            </a-tooltip>
            <a-tooltip title="Edit Program">
              <a-button
                v-if="record.registerCount <= 0"
                class="button"
                size="small"
                @click="editProgram(record)"
              >
                <i class="fe fe-edit" />
              </a-button>
              <a-button
                v-else
                disabled="true"
                class="button"
                size="small"
                @click="editProgram(record)"
              >
                <i class="fe fe-edit" />
              </a-button>
            </a-tooltip>
            <a-tooltip title="Konversi Poin">
              <a-button
                class="button"
                size="small"
                @click="btnConversionPoint(record)"
              >
                <i class="fa fa-compress" />
              </a-button>
            </a-tooltip>
            <a-tooltip title="User Pic">
              <a-button
                class="button"
                size="small"
                @click="btnUserPic(record)"
              >
                <i class="fa fa-user-md" />
              </a-button>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>
    <m-modal
      v-if="visible"
      v-model:visible="visible"
      :model="model"
      @handleOk="handleOk"
      :destroy-on-close="true"
    ></m-modal>
    <ConversionPointModal
      v-if="visibleConversionPoint"
      v-model:visible="visibleConversionPoint"
      :model="modelConversionPoint"
      @handleOk="handleOkConversionPoint"
      :destroy-on-close="true"/>

    <!-- detail -->
    <DetailUserPic
        v-if="visibleUserPicModal"
        v-model:visible="visibleUserPicModal"
        v-model:item="visibleUserPicItemModal"/>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, onMounted, computed, watch } from 'vue'
import MModal from './modal'
import ConversionPointModal from './ConversionPointModal'
import DetailUserPic from './DetailUserPic'
import acolumns from './columns'
import qs from 'qs'
import moment from 'moment'
import apiClient from '@/services/axios'
import { useRouter } from 'vue-router'
import FilterRegional from '@/components/filter/FilterRegional'

const initial = () => ({
  id: null,
  code: null,
  name: null,
  region: null,
  action_link: null,
  image: null,
  banner_web: null,
  banner_mobile: null,
  icon: null,
  title_intro: null,
  description_intro: null,
  tnc: null,
  start_date: null,
  end_date: null,
  default_point: null,
  access_clients_created_by: null,
  access_clients_updated_by: null,
  special_field_id_uom: null,
  list_value_special_field_id_uom: null,
  created_at: null,
  created_by: null,
  updated_at: null,
  updated_by: null,
  is_deleted: false,
  active: false,
  forms: null,
  record: {},
  toko: [],
})

export default defineComponent({
  components: {
    FilterRegional,
    MModal,
    ConversionPointModal,
    DetailUserPic,
  },
  emits: ['handleOk', 'handleOkConversionPoint'],
  setup() {
    const state = reactive({
      data: [],
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      meta: {
        totalCount: 0,
        perPage: 10,
        currentPage: 1,
      },
      page: 1,
      isFetching: false,
      isDownloading: false,
      search: null,
      periode: null,
      status: null,
      q: null,
      params: [],
      regional: [],
    })

    const router = useRouter()
    const visible = ref(false)
    const visibleConversionPoint = ref(false)
    const formState = reactive({ ...initial() })

    const handleChange = (pag, filters, sorter) => {
      meta.value.currentPage = pag.current
      meta.value.perPage = pag.pageSize
    }

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)
    const startRow = ref(1)

    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }

    const model = reactive({ readOnly: false })
    const modelConversionPoint = reactive({ readOnly: false })

    const showModal = () => {
      model.record = reactive({ ...initial() })
      model.readOnly = false
      visible.value = true
    }

    const showModalConversionPoint = () => {
      modelConversionPoint.record = reactive({ ...initial() })
      modelConversionPoint.readOnly = false
      visibleConversionPoint.value = true
    }

    const handleOk = form => {
      visible.value = false
      fetchData()
    }

    const handleOkConversionPoint = form => {
      visibleConversionPoint.value = false
    }

    const params = ref({})
    const errorMessage = ref()

    const columns = ref([])
    columns.value = acolumns

    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        region: state.regional,
        periode: state.periode,
        status: state.status,
        q: state.q,
      }
      state.params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/loyalty-programs', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          state.data = items
          state.meta = _meta || { pageCount: 0, totalCount: 0 }
          state.pageCount = state.meta.pageCount
          state.totalCount = state.meta.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    onMounted(() => {
      fetchData()
    })

    const lihatProgram = record => {
      visible.value = true
      model.record = record
      model.readOnly = true
    }

    const editProgram = record => {
      visible.value = true
      model.record = record
      model.readOnly = false
    }

    const btnConversionPoint = record => {
      visibleConversionPoint.value = true
      modelConversionPoint.record = record
      modelConversionPoint.readOnly = false
    }


    // handle user pic
    const visibleUserPicModal = ref(false);
    const visibleUserPicItemModal = ref(null);

    const btnUserPic = (item = null) => {
        visibleUserPicModal.value = true
        visibleUserPicItemModal.value = item
    }

    const search = () => {
      fetchData()
    }

    return {
      router,
      editProgram,
      lihatProgram,
      perPage,
      pageCount,
      totalCount,
      meta,
      page,
      startRow,
      visible,
      showModal,
      handleOk,
      columns,
      handleChange,
      handleTableChange,
      model,
      ...toRefs(formState),
      ...toRefs(state),
      search,
      errorMessage,
      moment,
      modelConversionPoint,
      btnConversionPoint,
      visibleConversionPoint,
      handleOkConversionPoint,
      showModalConversionPoint,
      // user pic handle
      visibleUserPicModal,
      visibleUserPicItemModal,
      btnUserPic,
    }
  },
})
</script>
<style scoped>
.card-body {
  margin-left: 20px;
}
.button {
  margin: 0 3px;
}
</style>
